import React from "react";
import { logout } from "./firebase";

export default function AdminNavBar() {
  return (
    <header className="bg-gray-50">
      <nav className="max-w-8xl mx-auto px-2 sm:px-3 lg:px-8" aria-label="Top">
        <div className="w-full py-3 flex items-center justify-between border-b border-indigo-500 lg:border-none">
          <div className="flex items-center">
            <a href="#">
              <span>Astilabor - admin</span>
            </a>
          </div>
          <div className="ml-10 space-x-2">
            <a
              href="/add-new-post"
              className="inline-block bg-white py-2 px-4 border border-transparent rounded-md text-base font-medium text-indigo-600 hover:bg-indigo-50"
            >
              Add new post
            </a>
            <a
              onClick={logout}
              className="inline-block bg-white py-2 px-4 border border-transparent rounded-md text-base font-medium text-indigo-600 hover:bg-indigo-50"
            >
              Sign Out
            </a>
          </div>
        </div>
      </nav>
    </header>
  );
}
