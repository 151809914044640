import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import AdminNavBar from "../components/utils/AdminNavBar";

import "../styles/base.css";
import ReactMarkdown from "react-markdown";
import {
  auth,
  deletePost,
  getPosts,
  toggleHiddenPost,
} from "../components/utils/firebase";

export default function BlogEdit() {
  const [user] = useAuthState(auth);

  if (typeof window !== `undefined`) {
    if (!user) {
      if (window) {
        navigate("/admin");
      }
    }
  }
  const rawPosts = getPosts();
  const [posts, setPosts] = useState([]);

  const handleDeletePost = async (id) => {
    deletePost(id).then(() => rawPosts.then((posts) => setPosts(posts)));
  };

  useEffect(() => {
    rawPosts.then((posts) => setPosts(posts));
  }, [rawPosts]);

  return (
    <>
      <AdminNavBar />
      <div className="relative bg-gray-50 pt-6 pb-20 px-4 sm:px-6 lg:pt-12 lg:pb-28 lg:px-8">
        <div className=" max-w-lg mx-auto grid gap-5 lg:grid-cols-4 lg:max-w-none">
          {posts.map((post) => (
            <div
              key={post.id}
              className="flex flex-col rounded-lg shadow-lg overflow-hidden"
            >
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <div className="block mt-2">
                    <p className="text-xl font-semibold text-gray-900">
                      {post.title}
                    </p>
                    <p className="mt-3 text-base text-gray-500">
                      <ReactMarkdown>{post.text.slice(0, 140)}</ReactMarkdown>
                    </p>
                  </div>
                </div>
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <a href={post.author}>
                      <span className="sr-only">{post.author}</span>
                    </a>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">
                      <a href={post.author} className="hover:underline">
                        {post.author}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <span className="relative z-0 inline-flex justify-end flex w-full rounded-md bg-white pb-4 pr-4">
                {!post.hidden ? (
                  <button
                    type="button"
                    onClick={() => toggleHiddenPost(post)}
                    className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                  >
                    Hide
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => toggleHiddenPost(post)}
                    className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-400 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                  >
                    Show
                  </button>
                )}

                <button
                  type="button"
                  onClick={() => handleDeletePost(post.id)}
                  className="-ml-px relative inline-flex text-red-600 items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium  hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                >
                  Remove
                </button>
              </span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
